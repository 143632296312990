import Vue from 'vue'
import VueLogger from 'vuejs-logger'
import store from '@/store'
import EventBus from '@/utils/event-bus'
import lodash, { forEach } from 'lodash-es'
import moment from 'moment-timezone'
import 'moment/locale/fr'
import VueScrollTo from 'vue-scrollto'
import StarRating from 'vue-star-rating'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  required,
  email,
  numeric,
  digits,
  min,
  regex,
  confirmed,
} from 'vee-validate/dist/rules'
import './icons' // icon
/*
 *
 */
import Navbar from '@/components/partials/Navbar/index.vue'
import CheckMyEligibilityModal from '@/apps/single-training/components/modals/CheckMyEligibilityModal.vue'

import '@/styles/index.scss'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
/*
 *
 */
const isProduction = process.env.NODE_ENV === 'production'
/*
 *
 */
window.dataLayer = window.dataLayer || []
/*
 *
 */
const vueLoggerOptions = {
  isEnabled: true,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
}
Vue.use(VueLogger, vueLoggerOptions)
Vue.use(VueScrollTo)
/*
 *
 */
Vue.component('Navbar', Navbar)
Vue.component('StarRating', StarRating)
Vue.component('CheckMyEligibilityModal', CheckMyEligibilityModal)

/*
 *
 */

extend('required', {
  ...required,
  message: 'Champ obligatoire ',
})
extend('email', {
  ...email,
  message: 'Adresse e-mail invalide',
})
extend('numeric', {
  ...numeric,
  message: 'le champ dois étre numérique',
})

extend('digits', {
  ...digits,
  message: '',
})
extend('min', {
  ...min,
  message: '',
})
extend('regex', {
  ...regex,
  message: '',
})
extend('confirmed', {
  ...confirmed,
  message: 'Les mots de passe ne correspondent pas',
})
/*
 *
 */

/*
 *
 */
Object.defineProperties(Vue.prototype, {
  $bus: {
    get() {
      return EventBus
    },
  },
})
Object.defineProperty(Vue.prototype, '$lodash', { value: lodash })
Object.defineProperty(Vue.prototype, '$moment', { value: moment })
/*
 *
 */
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

/*
 *
 */

Vue.config.productionTip = false

new Vue({
  el: '#app',
  store,
  created() {
    this.$store.commit('app/SET_API_BASEURL', process.env.VUE_APP_BASE_API)
  },
  computed: {
    ...mapFields('app', ['checkMyEligibilityModal']),
    selectedSessionPrice() {
      return this.selectedSession?.price ?? 0
    },
  },
  mounted() {
    const iligibilityButton = document.getElementById('iligibility-button')
    console.log('iligibilityButton ', iligibilityButton)
    if (iligibilityButton) {
      iligibilityButton.addEventListener('click', (e) => {
        e.preventDefault()
        this.sendCheckMyEligibility()
      })
    }
    this.$store.dispatch('app/fetchDiplomas')
  },
  methods: {
    sendCheckMyEligibility() {
      this.checkMyEligibilityModal.show()
    },
  },
})
